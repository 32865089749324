import React, { useEffect, useState } from 'react';
// Tools
import styled from 'styled-components';
import theme from 'theme';
import Icon from 'utils/icon';

const Paginate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media(max-width: 500px){
    position: fixed;
    bottom: 0;
    left: 0;
    background: ${theme.white}D0;
    z-index: 10;
    margin: 0;
    padding: 2px;
    backdrop-filter: blur(2px);
  }
`;

const Arrow = styled.div`
  color: ${({background}) => background || theme.ui.blue};
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background: ${({background}) => background || theme.ui.blue};
    color: ${theme.white};
  }
`;

const Number = styled.button`
  user-select: none;
  color: ${({color}) => color || theme.black};
  font-size: 16px;
  line-height: 16px;
  height: 32px;
  width: 32px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${({background}) => background || theme.ui.blue};
    color: ${theme.white};
  }

  ${({current, background}) => current && `
    color: ${background || theme.ui.blue};
    border: 1px solid ${background || theme.ui.blue};
  `}
`;

function ContentPaginate({
  // parameters
  backgroundColor,
  color,
  currentPage,
  limit,
  // state
  totalSessions,
  // actions
  setCurrentPage,
  onChange
}) {

  const [nbPages, setNbPages] = useState(1);

  useEffect(() => {
    setNbPages(Math.ceil(totalSessions/limit));
    setCurrentPage(1);
    onChange()
  },[totalSessions])

  useEffect(() => {
    onChange()
  }, [currentPage])

  const previous = () => {
    if(nbPages > 1 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const next = () => {
    if(currentPage < nbPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  const displayNumber = (n) => {
    return (
      <Number background={backgroundColor} color={color} current={currentPage == n ? true : false} onClick={()=>setCurrentPage(n)} key={n}>
        <div>{n}</div>
      </Number>
    )
  }

  return (
    <Paginate>
      <Arrow background={backgroundColor} color={color} onClick={previous}>
        <Icon icon="ArrowLeft1" size='xs' />
      </Arrow>
      {
        nbPages < 9 ?
          [...Array(nbPages).keys()].map((nbPage)=>{
            const n = nbPage + 1;
            return (
              displayNumber(n)
            )
          })
        :
          <React.Fragment>
          { displayNumber(1) }
          {
            currentPage < 6 &&
              [...Array(6).keys()].map((nbPage)=>{
                const n = nbPage + 2;
                return (
                  displayNumber(n)
                )
              })
          }
          {
            currentPage >= 6 && 
              <Number>...</Number>
          }
          {
             currentPage >= 6 && currentPage <= nbPages - 5 &&
              [...Array(5).keys()].map((nbPage)=>{
                const n = nbPage + (currentPage - 2);
                return (
                  displayNumber(n)
                )
              })
          }
          {
            currentPage <= nbPages - 5 && 
              <Number>...</Number>
          }
          {
            currentPage > nbPages - 5 &&
              [...Array(6).keys()].map((nbPage)=>{
                const n = nbPage + (nbPages - 6);
                return (
                  displayNumber(n)
                )
              })
          }
          { displayNumber(nbPages) }
          </React.Fragment>
      }
      <Arrow background={backgroundColor} color={color} onClick={next}>
        <Icon icon="ArrowRight1" size='xs'/>
      </Arrow>
    </Paginate>
  )
}

export default ContentPaginate;