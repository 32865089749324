import React, { useState, useEffect } from "react";
//  tools
import Icon from "utils/icon";
// style
import styled from "styled-components";
import theme from 'theme';
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { displayName } from "../../utils/displayUserName";

const VoteCard =  styled.div`
  border: 1px solid ${theme.ui.grey};
  border-radius: 5px;
  padding: 8px;
  width: calc(25% - 32px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @media(max-width: 1440px) {
    width: calc(33% - 21px);
  }

  @media(max-width: 1100px) {
    width: calc(50% - 22px);
  }

  @media(max-width: 768px) {
    width: 100%;
  }

  ${props=>props.result == 0 && `
    border-color: ${theme.lightred};
  `}
  ${props=>props.result == 1 && `
    border-color: ${theme.lightgreen};
  `}
  ${props=>props.result == 2 && `
    border-color: ${theme.ui.blue};
  `}
`;

const Username = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MandatingSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Mandating = styled.span`
  color: ${theme.blue};
  margin-top: 8px;
  font-size: 12px;
`;

const LeftSide = styled.div`
  width: 100%;
`;

const ResultTag = styled.div`
  border-radius: 50%;
  padding: 8px;
  color: white;

  ${props=>props.result == 0 && `
    background-color: ${theme.lightred};
  `}
  ${props=>props.result == 1 && `
    background-color: ${theme.green};
  `}
  ${props=>props.result == 2 && `
    background-color: ${theme.ui.blue};
  `}
`;

const Organization = styled.div`
  color: ${theme.gray};
  font-size: 14px;
  font-style: italic;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const UserVotes = ({userLists, proxies, results}) => {

  const [users, setUsers] = useState([]);

  useEffect(()=>{
    const usersTemp = [];
    if(userLists?.length) {
      userLists.forEach((userList)=>{
        if(userList.userListUser?.length) {
          userList.userListUser.forEach((user)=>{
            if(user?.user){
              usersTemp.push(user?.user);
            }
          })
        }
      })
    }
    setUsers([...new Set(usersTemp)]);
  }, [userLists])

  return (
    <React.Fragment>
      {
        users.map((user)=>{
          let result = null;
          const proxy = proxies?.find((proxy)=>proxy?.mandating?.userId == user.userId);
          if(proxy) {
            result = results.find((result)=>result.by?.userId == proxy?.mandating?.userId);
          }
          else {
            result = results.find((result)=>result.by?.userId == user.userId);
          }
          return (
            <VoteCard key={user.userId} result={result?.simpleResult}>
              <LeftSide>
                <Username>{displayName(user)}</Username>
                {
                  user?.organization &&
                    <Organization>{user.organization}</Organization>
                }
                {
                  proxy &&
                  <MandatingSection>
                    <Mandating><FormattedMessage {...messages.by_proxy}/> : {displayName(proxy?.mandatary)} </Mandating>
                  </MandatingSection>
                }
              </LeftSide>
              <ResultTag result={result?.simpleResult}>
                {
                    result?.simpleResult === 0 ?
                    <Icon icon="CrossWhite" size="sm" />
                    : result?.simpleResult === 1 ?
                    <Icon icon="Check" size="sm" />
                    : result?.simpleResult === 2 ?
                    <Icon icon="RemoveWhite" size="sm" />
                    : <></>
                }
              </ResultTag>
            </VoteCard>
          )
        })
      }
    </React.Fragment>
  )
}

export default UserVotes;