import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import styled from "styled-components";

const DocImg = styled.img`
  width: 100%;
  height: 80vh;
  object-fit: contain;
  &.source {
    opacity: 0;
    transition: opacity 1200ms;
  }
  &.loaded {
    opacity: 1;
  }
`;

export function Lazyload({src, alt, lqip}) {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, []);
  return (
    <div>
      {lqip && <img src={lqip} alt="" aria-hidden="true" />}
      <DocImg
        loading="lazy"
        src={src}
        alt={alt}
        ref={imgRef}
        onLoad={() => setLoaded(true)}
        className={clsx("source", loaded && "loaded")}

      />
    </div>
  );
}
