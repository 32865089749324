import React from "react";
import DatePicker from 'react-datepicker'
import {injectIntl} from 'react-intl';

const DatePickerDE = ({filters, handleChange, locale, customInput}) => {

   return(
        <DatePicker
            selected={filters.endDate}
            selectsEnd
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={handleChange}
            dateFormat="dd/MM/yyyy"
            locale={locale}
            customInput={customInput}
        />
    );
}

export default injectIntl(DatePickerDE);

   
