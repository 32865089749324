import { defineMessages } from "react-intl";
export default defineMessages({
  voting: {
    id: "a2vote.uservotes.voting",
    defaultMessage: "Voting",
  },
  by_proxy: {
    id: "a2vote.uservotes.by.proxy",
    defaultMessage: "by proxy",
  },
  organization: {
    id: "a2vote.uservotes.organization",
    defaultMessage: "Organization",
  }
})